// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiBaseUrl: 'https://welfare-backend.securus.in/api/',
  apiBaseUrl: 'https://welfare-backend.securus.in/api/', // 'https://staging-welfare-backend.securus.in/api/',
  // appName: 'SIBRO BUDDY'

  // apiBaseUrl:  'https://welfare-backend.securus.in/api/',
  appName: 'SIBRO BUDDY'
  // 'https://welfare-backend.securus.in/api/',
  // 'https://enrollment-backend.protracked.in/api/',
  // ** orange capital **//
  // apiBaseUrl: 'https://backend-customerservice.orangecapital.in/api/', // 'https://staging-backend-customerservice.orangecapital.in/api/',
  // appName: 'Orange Capital'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
