import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {Storage} from '@ionic/storage';
import {AlertController, IonRouterOutlet, LoadingController, MenuController, ModalController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {LogoutAlertPage} from './modal/logout-alert/logout-alert.page';
import {ConstantsService} from './services/constants.service';
import {HospitalListConfirmPage} from './modal/hospital-list-confirm/hospital-list-confirm.page';
import {finalize, first} from 'rxjs/operators';
import {EmployeeService} from './services/employee.service';
import {EventService} from './services/event.service';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { PolicyService } from './services/policy.service';
import { NetworkProviderService } from './services/network-provider.service';

import { Location } from '@angular/common';
import {ReplaceCompanyService} from './services/replace-company.service';
import * as moment from 'moment';
import { AuthenticationService } from './services/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  isMenuOpen = false;
  activePath = '';
  showLevel1 = null;
  showPolicyLevel1 = null;
  showPolicyLevel2 = null;
  showLevel2 = null;
  showLevel3 = null;
  employeeDetails: any;
  employeeName = 'SIBRO BUDDY';
  employeeEmail = '';
  sumInsured = '';
  clientLogo = '';
  memberFirstName = '';
  memberMiddleName = '';
  memberLastName = '';
  policyId;
  policyType: any = 'gmc';
  enrollUrl: any = '';
  enrollments: any = [];
  primaryEnrollmentStatus: any  = 0;
  nomineeStatus: any = false;
  networkStatus = true;
  public policy: any = [];
  public appPages: any = [];
  gmc: any = 0;
  gpa: any = 0;
  gtl: any = 0;
  sideBarLogo;
  onSuccess = (data) => {
    if (data.policies) {
      this.nomineeStatus = data.policies.length > 0;
      // this.nomineeStatus = data.policies.length > 0 ? true : false;
    }
    else {
      // console.log('data:', data);
      this.nomineeStatus = false;
    }
    // console.log(this.nomineeStatus);
    // this.employeeDetails = data.employee;
    // Object.keys(this.employeeDetails).forEach(key => {
    //   if (this.employeeDetails[key]) {
    //     if (key === 'member_first_name') {
    //       this.memberFirstName = this.employeeDetails[key].value;
    //     } else if (key === 'member_middle_name') {
    //       this.memberMiddleName = this.employeeDetails[key].value;
    //     } else if (key === 'member_last_name') {
    //       this.memberLastName = this.employeeDetails[key].value;
    //     } else if (key === 'email') {
    //       this.employeeEmail = this.employeeDetails[key].value;
    //     } else if (key === 'sum_insured') {
    //       if (this.employeeDetails[key].value) {
    //         this.sumInsured = this.employeeDetails[key].value;
    //       }
    //     } else if (key === 'family_sum_insured') {
    //       if (this.employeeDetails[key].value) {
    //         this.sumInsured = this.employeeDetails[key].value;
    //       }
    //     } else if (key === 'member_sum_insured') {
    //       if (this.employeeDetails[key].value) {
    //         this.sumInsured = this.employeeDetails[key].value;
    //       }
    //     }
    //     // tslint:disable-next-line:max-line-length
    //     this.employeeName = this.memberFirstName + ' ' + this.memberMiddleName  + ' ' + this.memberLastName;
    //   }
    // });
  }
  onError = (error) => {
    if (error.error.message === 'Unauthenticated.') {
      this.router.navigateByUrl('login');
    }
  }
  onEnrollmentSuccess = (result) => {
    if(result.length > 0) {
      var startTime = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
      var endTime = moment(result[0].end_time, 'YYYY-MM-DD hh:mm:ss');
      var secondsDiff = endTime.diff(startTime, 'seconds');
      if(secondsDiff <= 0) {
        this.enrollUrl = null;
      } else {
        this.enrollUrl = result[0].url;
      }
      const urlData = result[0].url.split('?e=');
      if (urlData.length > 1) {
        this.storage.set('enrollmentUrlId', urlData[1]);
      }
    } else {
      this.enrollUrl = null;
    }
  };
  onEnrollmentError = (error) => {
    console.log(error);
  }
  employeeId;
  constructor(
    private platform: Platform,
    protected replaceCompany: ReplaceCompanyService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private elementRef: ElementRef,
    protected router: Router,
    protected storage: Storage,
    protected employeeService: EmployeeService,
    protected loadingController: LoadingController,
    protected authenticate: AuthenticationService,
    protected constantsService: ConstantsService,
    protected modalCtrl: ModalController,
    protected menuCtrl: MenuController,
    protected event: EventService,
    public push: Push,
    protected policyService: PolicyService,
    protected networkProvider: NetworkProviderService,
    protected alertController: AlertController,
    private location: Location,
  ) {
    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      Promise.all([this.storage.get('enrollmentUrl'), this.storage.get('encryptedEmployeeId')])
      .then(values => {
        if (values[0] === false) {
          this.getEmployeeDetails();
        }
        if(values[1]) {
          this.employeeId = values[1];
          this.employeeEnrollment();
          }
      });
      this.setThemeColor();
      // this.initPushNotification();
      // console.log('from init');
      // this.networkProvider.initializeNetworkEvents();
      this.networkProvider.appIsOnline$.subscribe(online => {
        // console.log('network:', online);
        this.networkStatus = online;
        this.event.publish('network:status', online);
      });
      this.event.subscribe('user:created', (data: any) => {
        // ('Welcome', data);
        // console.log('from event');
        this.getEmployeeDetails();
      });
      this.event.subscribe('sum:changed', (data: any) => {
        // ('Welcome', data);
        // console.log('from event');
        this.sumInsured = data.value;
      });
      this.event.subscribe('nomineeStatus:changed', (data: any) => {
        // ('Welcome', data);
        // console.log('from event', data);
        this.nomineeStatus = data;
      });
      // Offline event
      this.event.subscribe('network:offline', () => {
         alert('network:offline ==> ');
      });

      // Online event
      this.event.subscribe('network:online', () => {
         alert('network:online ==> ');
      });

      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        // console.log('Back press handler!', this.router.url.split('?'));
        if (this.location.isCurrentPathEqualTo('/employee/tabs') ||
            this.location.isCurrentPathEqualTo('/employee/tabs/overview') ||
            this.location.isCurrentPathEqualTo('/employee/tabs/enrollment?' + this.router.url.split('?')[1]) ||
            this.location.isCurrentPathEqualTo('/enrollment?' + this.router.url.split('?')[1])) {
          // Show Exit Alert!
          // console.log('Show Exit Alert!');
          this.showExitConfirm();
          processNextHandler();
        } else {
          // Navigate to back page
          // console.log('Navigate to back page');
          this.location.back();
        }
      });
      this.platform.backButton.subscribeWithPriority(5, () => {
        // console.log('Handler called to force close!');
        this.alertController.getTop().then(r => {
          if (r) {
            navigator['app'].exitApp();
          }
        }).catch(e => {
          console.log(e);
        });
      });
      this.getEmployeeDetails();
    });
  }
  /**
   * setting default theme
   */
  protected setThemeColor() {
    this.elementRef.nativeElement.style.setProperty('--ion-custom-primary', this.replaceCompany.primaryColor);
    this.elementRef.nativeElement.style.setProperty('--ion-custom-light', this.replaceCompany.lightColor);
    this.elementRef.nativeElement.style.setProperty('--ion-custom-secondary', this.replaceCompany.secondaryColor);
    this.statusBar.backgroundColorByHexString(this.replaceCompany.primaryColor);
    this.sideBarLogo = this.replaceCompany.getLogo();
  }
  ionViewWillEnter() {
    this.getEmployeeDetails();
    Promise.all([this.storage.get('encryptedEmployeeId')])
      .then(value => {
        if(value[0]) {
          console.log(value[0])
        this.employeeId = value[0];
        this.employeeEnrollment();
        }
      });
  }
  showExitConfirm() {
    this.alertController.create({
      header: 'App Exit',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          // console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }
  employeeEnrollment() {
    this.authenticate.employeeEnrollment(this.employeeId)
    .pipe(
        finalize(() => this.loadingController.dismiss().then((res) => {}).catch((error) => {})),
        first()
    )
    .subscribe(
        this.onEnrollmentSuccess,
        this.onEnrollmentError,
    );
  }
  /**
   * get employee details
   */
  getEmployeeDetails() {
    Promise.all([this.storage.get('employee'), this.storage.get('gmc_prospect_id'), this.storage.get('gpa_prospect_id'),
      this.storage.get('gtl_prospect_id'), this.storage.get('client_logo'), this.storage.get('gmc_status'),
      this.storage.get('gpa_status'), this.storage.get('gtl_status'), this.storage.get('enrollmentUrlId'), this.storage.get('primary_enrollment_policy'),
      this.storage.get('enrollments'), this.storage.get('client_id')])
        .then(values => {
          // console.log('gmc count:', values[5]);
          // console.log('gpa count:', values[6]);
          // console.log('gtl count:', values[7]);
          this.memberFirstName = '';
          this.memberMiddleName = '';
          this.memberLastName = '';
          this.employeeName = '';
          this.employeeEmail = '';
          this.sumInsured = '';
          this.clientLogo = '';
          this.gmc = 0;
          this.gpa = 0;
          this.gtl = 0;
          if (values[4]) {
            this.clientLogo = values[4];
          }
          if (values[1]){
            // console.log(values[1]);
            // console.log(values[2]);
            // console.log(values[3]);
          }
          if (values[0]){
            this.employeeDetails = values[0];
            Object.keys(this.employeeDetails).forEach(key => {
              if (this.employeeDetails[key]) {
                // console.log('key', this.employeeDetails[key]);
                if (key === 'member_first_name') {
                  this.memberFirstName = this.employeeDetails[key].value;
                } else if (key === 'member_middle_name') {
                  this.memberMiddleName = this.employeeDetails[key].value;
                } else if (key === 'member_last_name') {
                  this.memberLastName = this.employeeDetails[key].value;
                } else if (key === 'email') {
                  this.employeeEmail = this.employeeDetails[key].value;
                } else if (key === 'sum_insured') {
                  if (this.employeeDetails[key].value) {
                    this.sumInsured = this.employeeDetails[key].value;
                  }
                } else if (key === 'family_sum_insured') {
                  if (this.employeeDetails[key].value) {
                    this.sumInsured = this.employeeDetails[key].value;
                  }
                } else if (key === 'member_sum_insured') {
                  if (this.employeeDetails[key].value) {
                    this.sumInsured = this.employeeDetails[key].value;
                  }
                } else if (key === 'employee_id') {
                  if (this.employeeDetails[key].value ) {
                    if (this.networkStatus) {
                      this.getNomineeDetails(this.employeeDetails[key].value, values[11]);
                    }
                    // this.employeeId = this.employeeDetails[key].value;
                    this.storage.set('employee_id', this.employeeDetails[key].value);
                  }
                }
                this.employeeName = this.memberFirstName + ' ' + this.memberMiddleName + ' ' + this.memberLastName;
              }
            });
          }
          let policyId;
          this.appPages = [];
          if (values[5] > 0) {
            policyId = values[1];
            this.gmc = values[5];
            this.appPages.push(this.constantsService.gmcSideMenuData());
          }
          if (values[6] > 0) {
            // policyId = values[2];
            this.gpa = values[6];
            this.appPages.push(this.constantsService.gpaSideMenuData());
          }
          if (values[7] > 0) {
            // policyId = values[3];
            this.gtl = values[7];
            this.appPages.push(this.constantsService.gtlSideMenuData());
          }
          this.policyId = policyId;
          // this.enrollUrl = values[8];
          this.primaryEnrollmentStatus = values[9];
          this.enrollments = values[10];
          // console.log(this.enrollUrl);
          // this.getUserDetails();
          // this.appPages.push(this.constantsService.basicSideMenu());
    });
  }
  // getUserDetails() {
  //   this.employeeService.getEmployeeData(this.policyId)
  //       .pipe(
  //           first()
  //       )
  //       .subscribe(
  //           this.onSuccess,
  //           this.onError,
  //       );
  // }
  getNomineeDetails(employeeId, clientId) {
    this.policyService.getNomineePolicy(employeeId, clientId)
        .pipe(
            first()
        )
        .subscribe(
            this.onSuccess,
            this.onError,
        );
  }
  /**
   * call confirm alert for logout
   */
  async logout() {
    await this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: LogoutAlertPage,
      backdropDismiss: true,
      componentProps: {
        type: 'logout',
      },
    });
    modal.onDidDismiss()
        .then((data) => {
          if (data.data) {
            this.menuCtrl.enable(false);
            location.reload();
          }
        });
    await modal.present();
  }

  async enrollmentUrl() {
    // await this.router.navigateByUrl('employee/tabs/coverage');
    let id;
    await this.storage.get('enrollmentUrlId').then(value => {
      console.log('Erlid:', value);
      id = value;
        // this.router.navigateByUrl('employee/tabs/enrollment' + 'e='+value);
    });
    // await this.router.navigateByUrl('employee/tabs/enrollment' + '?e=' + id);
    // await this.router.navigateByUrl('employee/tabs/enrollment' + '?e=' + id);
    this.storage.set('sideMenu', true);
    // this.menuCtrl.enable(true);
    await this.router.navigateByUrl('employee/tabs/enrollment?e=' + id);
    // await this.router.navigate(['enrollment' + '?e=' + id], {queryParams: {sideMenu: true}});
    await this.menuCtrl.close();
  }

  async goToEnrollment(enrollment) {
    // await this.router.navigateByUrl('employee/tabs/coverage');
    // console.log('This gmc:', this.gmc);
    // console.log('This gtl:', this.gtl);
    // console.log('This gpa:', this.gpa);
    this.event.publish('enrollment:new', {
      time: new Date(),
      enrollmentUrl: 'employee/tabs/enrollment?' + enrollment.url.split('?')[1]
      });
    if ( this.gmc === 0 && this.gpa === 0 && this.gtl === 0) {
      await this.router.navigateByUrl('enrollment?' + enrollment.url.split('?')[1]);
    }
    else {
      await this.router.navigateByUrl('employee/tabs/enrollment?' + enrollment.url.split('?')[1]);
    }
    await this.menuCtrl.close();
  }

  /**
   * menu toggle action
   */
  toggleAccordion(): void {
    // console.log('from toggle');
    this.getEmployeeDetails();
    this.isMenuOpen = !this.isMenuOpen;
  }

  /**
   * close button for closing menu
   */
  closeMenu() {
    this.menuCtrl.toggle();
  }

  /**
   * navigate to coverage
   */
  async getCoverage() {
    await this.router.navigateByUrl('employee/tabs/coverage');
    await this.menuCtrl.close();
  }

  /**
   * navigate to claims
   */
  async getClaims() {
    await this.router.navigateByUrl('employee/tabs/claims');
    await this.menuCtrl.close();
  }

  /**
   * navigate to health card
   */
  async getHealthCard() {
    await this.router.navigateByUrl('employee/tabs/health-card');
    await this.menuCtrl.close();
  }

  /**
   * navigate to overview
   */
  async getOverview() {
    await this.router.navigateByUrl('employee/tabs/overview');
    await this.menuCtrl.close();
  }

  /**
   * navigate to faq
   */
  async getFaq() {
    await this.router.navigateByUrl('employee/tabs/overview/faq');
    await this.menuCtrl.close();
  }

  /**
   * navigate to overview
   */
  async goToPages(item) {
    // console.log('on page', item);
    this.storage.set('policyType', item.type)
                .then(data => {
                  this.policyType = item.type;
                  this.event.publish('policytype:updated', {
                        policyType: item.type,
                        time: new Date()
                        });
                });

    await this.router.navigateByUrl(item.url);
    window.location.reload();
    await this.menuCtrl.close();
  }

  /**
   * clear all menu level
   */
  clearLevel() {
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
  }
  togglePolicyLevel1(idx: string) {
    if (this.isLevel1Shown(idx)) {
      this.showPolicyLevel1 = null;
    } else {
      this.showPolicyLevel1 = idx;
    }
  }
  togglePolicyLevel2(idx: string) {
    if (this.isLevel2Shown(idx)) {
      this.showPolicyLevel2 = null;
    } else {
      this.showPolicyLevel1 = idx;
      this.showPolicyLevel2 = idx;
    }
  }
  isPolicyLevel1Shown(idx: string) {
    return this.showPolicyLevel1 === idx;
  }
  isPolicyLevel2Shown(idx: string) {
    return this.showPolicyLevel2 === idx;
  }
  /**
   * toggle menu level 1
   */
  toggleLevel1(idx: string, i, label) {
    // console.log('idx l1:',i);
    // console.log('label:',label);
    if (this.isLevel1Shown(idx)) {
      this.showLevel1 = null;
    } else {
      this.showLevel1 = idx;
    }
    if (label === 'Logout') {
      this.logout();
    } else if (label === 'Enrollment') {
      this.enrollmentUrl();
    }
  }
  isLevel1Shown(idx: string) {
    return this.showLevel1 === idx;
  }

  /**
   * toggle menu level 2
   */
  toggleLevel2(idx: string, subItem) {
    // console.log('idx l2:',idx);
    if (this.isLevel2Shown(idx)) {
      this.showLevel2 = null;
    } else {
      this.showLevel1 = idx;
      this.showLevel2 = idx;
    }
    // console.log(subItem);
    if (subItem.url) {
      this.menuCtrl.close();
    }
    this.storage.set('policy_type', subItem.type);
  }
  isLevel2Shown(idx: string) {
    return this.showLevel2 === idx;
  }

  /**
   * toggle menu level 3
   */
  toggleLevel3(idx: string, subItem) {
    // console.log('idx l3:',idx);
    if (this.isLevel3Shown(idx)) {
      this.showLevel3 = null;
    } else {
      this.showLevel2 = idx;
      this.showLevel3 = idx;
    }
    // console.log(subItem);
    if (subItem.url) {
      this.menuCtrl.close();
    }
    if (subItem.name === 'Hospital List') {
      this.menuCtrl.close();
      this.getHospitalList();
    }
  }
  isLevel3Shown(idx: string) {
    return this.showLevel3 === idx;
  }
  async getHospitalList() {
    const modal = await this.modalCtrl.create({
      component: HospitalListConfirmPage,
      componentProps: {
        from: 'dashboard',
      },
      backdropDismiss: true,
    });
    modal.onDidDismiss()
        .then((data) => {
          if (data.data) {}
        });
    await modal.present();
  }
  /**
   * Initialize Fiebase push
   */
   initPushNotification() {
    if (!this.platform.is('cordova')) {
      console.warn('Push notifications not initialized. Cordova is not available - Run in physical device');
      return;
    }
    // to check if we have permission
    this.push.hasPermission()
        .then((res: any) => {

          if (res.isEnabled) {
            console.log('We have permission to send push notifications');
          } else {
            console.log('We do not have permission to send push notifications');
          }

        });

    // Create a channel (Android O and above). You'll need to provide the id, description and importance properties.
    this.push.createChannel({
        id: 'testchannel1',
        description: 'My first test channel',
        // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
        importance: 3,
        // badge is used to if badge appears on the app icon see
      // https://developer.android.com/reference/android/app/NotificationChannel.html#setShowBadge(boolean).
        // false = no badge on app icon.
        // true = badge on app icon
        badge: false
    }).then(() => console.log('Channel created'));

    // Delete a channel (Android O and above)
    this.push.deleteChannel('testchannel1').then(() => console.log('Channel deleted'));

    // Return a list of currently configured channels
    this.push.listChannels().then((channels) => console.log('List of channels', channels));

    // to initialize push notifications
    const options: PushOptions = {
      android: {
        senderID: '324372436028'
      },
      ios: {
        alert: 'true',
        badge: false,
        sound: 'true'
      },
      windows: {},
      browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
      }
    };
    const pushObject: PushObject = this.push.init(options);

    pushObject.on('registration').subscribe((data: any) => {
      // console.log('device token -> ' + data.registrationId);
      this.storage.set('fcmToken', data.registrationId);
      localStorage.setItem('fcm_token', data.registrationId);
      // TODO - send device token to server
    });

    pushObject.on('notification').subscribe((data: any) => {
      // console.log('message -> ', data);
      if (data.additionalData.foreground) {
            // If the app is running when the push received
            // alert('foreground '+data.message);
            // TODO: Your stuff
            this.event.publish('notification:new', {
              user: 'user created',
              time: new Date()
            });
            } else {
              //   if(data.additionalData.coldstart) {
              // //If the app is closed and started by clicking on the push notification
              //     alert(data.message);
              //     //TODO: Your stuff
              //   } else {
              //   //If the app is open but not active. i.e. running in background
              //       alert(data.message);
              //       console.log('Push notification clicked');
              //       // TODO: Your stuff
              //   }
          }
    });

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin' + error));
   }
  async goToNomineepage() {
    // await this.router.navigateByUrl('employee/tabs/coverage');
    // console.log('This gmc:', this.gmc);
    // console.log('This gtl:', this.gtl);
    // console.log('This gpa:', this.gpa);
    if ( this.gmc === 0 && this.gpa === 0 && this.gtl === 0) {
      await this.router.navigateByUrl('nominee');
    }
    else {
      await this.router.navigateByUrl('employee/tabs/nominee');
    }
    await this.menuCtrl.close();
  }

  getSumInsured(value) {
    // console.log(value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
    // return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    if (value) {
      return parseInt(value).toLocaleString('en-IN');
      // return parseInt(value).toLocaleString('en-IN', {
      //     style: 'currency',
      //     currency: 'INR'
      // });
    }
    return value;
  }
}
